import React from "react"
import { Link, graphql } from "gatsby"

import { MDXRenderer } from "gatsby-plugin-mdx"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader"
deckDeckGoHighlightElement()

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.mdx
  // const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  return (
    <Layout location={location}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        <header
          style={{ minHeight: "35vh" }}
          className="flex justify-center items-center flex-col"
        >
          <h1 className="md:text-6xl text-3xl uppercase font-logoFont">
            {post.frontmatter.title}
          </h1>
          <p>{post.frontmatter.date}</p>
        </header>
        <div className="markdown lg:w-1/2 md:w-2/3 sm:w-5/6 w-full px-4 font-roboto text-black mx-auto">
          <MDXRenderer>{post.body}</MDXRenderer>
        </div>
        <hr />
        <footer>
          <Bio />
        </footer>
      </article>

      <nav>
        <ul className="flex flex-wrap justify-between list-none p-6">
          <li>
            {previous && (
              <Link to={`/voyage${previous.fields.slug}`} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`/voyage${next.fields.slug}`} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
